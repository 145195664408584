import React from 'react'
import {Link} from 'gatsby'
import './exhibitionNavBar.css'

const ExhibitionNavBar = ({slug, bureauAudio }) => {      
        const currentYear = new Date().getFullYear()

    const renderBureauAudio = () =>{
        {/*if(bureauAudio === true)*/}
        {
            return (
                    <>
                        <li className="nav-li">|</li>
                        <li className="nav-li" ><a href="http://www.bureau.audio/" target="_blank" rel="noreferrer" >Bureau Audio</a></li>
                    </>
            )
        }
    }

    return (
        <>
            <ul className="artist-nav">
                <li className="nav-li" ><Link to="/exhibitions-future" activeClassName='current' >Future</Link></li>
                <li className="nav-li">|</li>
                <li className="nav-li" ><Link to="/exhibitions" activeClassName='current' >Current</Link></li>
                <li className="nav-li">|</li>
                <li className="nav-li" ><Link to={`/exhibitions/archive/${slug? slug:currentYear}`} activeClassName='current' partiallyActive={true}>Archive</Link></li>
                {renderBureauAudio()}
            </ul>
        </>

    )
}

export default ExhibitionNavBar