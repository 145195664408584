import React from 'react'
import './exhibitionArchiveNav.css'
import { StaticQuery, graphql } from "gatsby"
import {Link} from 'gatsby'

export default function ArchiveNavBar({slug}) {


    const renderNav = (data) => {
        const years = data.sort((a, b)=>{return b-a})
          return years.map(year => 
                      <>
                        <li key={year}>
                          <Link to={`/exhibitions/archive/${year}`} key={year} activeClassName='current' partiallyActive={true}>{year} </Link>
                        </li>
                        {years.indexOf(year)+1 === years.length? null:<li>|</li>}
                      </>
              )
        } 

    return (
        <StaticQuery
      query={graphql`
        query HeadingQuery {
            allContentfulExhibitionsArchive {
                edges {
                  node {
                    year
                  }
                }
              }
        }
      `}
      render={data => (
        <div className='arc-cont'>
           <ul className='archive-nav-cont'>
               {renderNav(data.allContentfulExhibitionsArchive.edges.map(year => parseInt(year.node.year)))}
            </ul> 
        </div>
      )}
    />
    )
}
