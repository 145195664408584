import React from 'react'
import { graphql } from 'gatsby'
// import { Helmet } from 'react-helmet'
// import get from 'lodash/get'
import Layout from '../components/layout'
import ArchiveNavBar from '../components/exhibitionArchiveNav'
import ExhibitionNavBar from '../components/exhibitionNavBar'
import {INLINES} from "@contentful/rich-text-types"
import {Link }from 'gatsby'
import './exhibition-archive-year.css'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import SEO from "../components/seo"

class ExhibtionArchiveYearTemplate extends React.Component {
  render() {

    const options = {
      renderNode: {
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          return <Link key={node.data.target.slug} to={`/exhibitions/${node.data.target.slug}`} >{children}</Link>
        },
        [INLINES.ASSET_HYPERLINK]: (node, children) => {
          return <a href={node.data.target.file.url} key={node.data.target.slug} className="">{children}</a>
        }
    }
  }
        const {slug, year} = this.props.data.contentfulExhibitionsArchive        
  

        const renderExhibitions = () => {
          if(this.props.data.contentfulExhibitionsArchive.exhibitions != null)
          {
            return <div className="archive-container">{renderRichText(this.props.data.contentfulExhibitionsArchive.exhibitions, options)}</div>
          }
          else
          {
            return <h1>Archive Coming Soon</h1>
          }
        }

    return (
      <Layout location={this.props.location}>
        <SEO 
          title={`${year} Exhibitions`}
          description={`Archive of all exhibitions for ${year} at Bureau`}
        />
          <ExhibitionNavBar slug={slug} bureauAudio={true}/>
            <ArchiveNavBar />
                {renderExhibitions()}        
      </Layout>
    )
  }
}

export default ExhibtionArchiveYearTemplate

export const pageQuery = graphql
`
query exhibitionyear($slug: Date!){
  contentfulExhibitionsArchive(slug: {eq: $slug}) {
      year
      exhibitions {
        raw
        references {
          ... on ContentfulExhibition {
            # contentful_id is required to resolve the references
            contentful_id
            title
            slug
          }
        } 
      }
      slug
    }
  }
  `

  // exhibits {
  //   id
  //   exhibitionInformation {
  //     raw
  //   }
  //   artist
  //   date
  //   endDate
  //   groupExhibition
  //   title
  //   slug
  // }